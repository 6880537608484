import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banner1 from '../assets/banner1.webp'
import banner2 from '../assets/banner2.webp'

export default class CarouselBox extends Component {
    render() {
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        src={banner1}
                        alt='banner1'
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        src={banner2}
                        alt='banner2'
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className='d-block w-100'
                        src={banner1}
                        alt='banner1'
                    />
                </Carousel.Item>
            </Carousel>
        );
    }
}
