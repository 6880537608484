import React, { Component } from 'react';
import { Form, Button, Container } from 'react-bootstrap';

class Contacts extends Component {
    render() {
        return (
            <Container style={{width:'50%'}}>
                <h1 className='text-center'>Placeholder!</h1>
            </Container>
        );
    }
}

export default Contacts;