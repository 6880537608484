import React, { Component } from "react";
import { Button, Container, Form, FormControl, Navbar, Nav, NavbarCollapse } from "react-bootstrap";
import logo from "./logo_tm.png";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "../Pages/Home";
import About from "../Pages/About";
import Contacts from "../Pages/Contacts";
import News from "../Pages/News";

export default class Header extends Component {
    render() {
        return (
            <div>
                <Navbar sticky="top" collapseOnSelect expand='md' bg='dark' variant='dark' className="navbar-bg">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src={logo}
                                height="40"
                                width="180"
                                className="d-inline-block align-top"
                                alt='logo'
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <NavbarCollapse id="responsive-navbar-nav">
                            <Nav className="me-auto justify">
                                <Nav.Link href="/"> Головна </Nav.Link>
                                <Nav.Link href="/News"> Новини </Nav.Link>
                                <Nav.Link href="/about"> Про компанію </Nav.Link>
                                <Nav.Link href="/contacts"> Контакти </Nav.Link>
                            </Nav>
                            <Form className='d-flex' >
                                <FormControl
                                    type='text'
                                    placeholder="Search"
                                    className="me-sm-2"
                                />
                                <Button className="searchbutton" variant="outline-info">Search</Button>
                            </Form>
                        </NavbarCollapse>
                    </Container>
                </Navbar>

                <Router>
                    <Routes>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/about' element={<About />} />
                        <Route exact path='/contacts' element={<Contacts />} />
                        <Route exact path='/News' element={<News />} />
                    </Routes>
                </Router>
            </div>
        )
    }
}