import React, { Component } from 'react';
import CarouselBox from '../Components/CarouselBox';
import { Container, CardGroup, Card, Button } from 'react-bootstrap';

export default class Home extends Component {
    render() {
        return (
        <div>
            <CarouselBox />
            <Container>
                <h2 className='text-center mt-4'>What is this? THIS IS HOMEPAGE!!!</h2>
                <CardGroup className='m-4'>
                    <Card border='primary' text='primary' className='m-3 home-card'> 
                        <Card.Img
                            variant='top'
                            src='https://okta.ua/images/news/l/1691392933m_65728-2.webp'
                        />
                        <Card.Body>
                            <Card.Title>title1</Card.Title>
                            <Card.Text>
                                Притворитесь, что тут круто расписано про нас.
                            </Card.Text>
                            <Button variant='primary'>About title1</Button>
                        </Card.Body>
                    </Card>
                    <Card border='dark' text='dark' className='m-3 home-card'> 
                        <Card.Body>
                            <Card.Title>title2</Card.Title>
                            <Card.Text>
                                Притворитесь, что тут круто расписано про нас.
                            </Card.Text>
                            <Button variant='dark'>About title2</Button>
                        </Card.Body>
                        <Card.Img
                            variant='bottom'
                            src='https://okta.ua/images/news/l/1693851173m_okta-anchor-new-14.webp'
                        />
                    </Card>
                    <Card border='success' text='success' className='m-3 home-card'> 
                        <Card.Img
                            variant='top'
                            src='https://okta.ua/images/news/l/1693302572m_IRV-Broschuere.webp'
                        />
                        <Card.Body>
                            <Card.Title>title3</Card.Title>
                            <Card.Text>
                                Притворитесь, что тут круто расписано про нас.
                            </Card.Text>
                            <Button variant='success'>About title3</Button>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Container>
        </div>
            );
    }
}

