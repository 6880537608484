import React, { Component } from 'react';
import { Container, Col, Row, ListGroup, Card } from 'react-bootstrap';
import NET from '../network';

class News extends Component {
    constructor() {
        super();
        this.state = {
            newsData: [],
        };
    }

    componentDidMount() {
        fetch(`${NET.APP_URL}/news`)
            .then(response => response.json())
            .then(data => {
                this.setState({ newsData: data });
            })
            .catch(error => console.error('Ошибка при запросе данных:', error));
    }

    render() {
        const { newsData } = this.state;

        return (
            <div>
                <Container>
                    <Row>
                        <Col md="9">
                            {newsData.map(news => (
                                <div key={news.id} className='m-5'>
                                    <Row>
                                        <Col md="3">
                                            <img
                                                alt=':('
                                                width={150}
                                                height={150}
                                                className='mr-2'
                                                src={news.photo}
                                            />
                                        </Col>
                                        <Col md="9">
                                            <h5>{news.name}</h5>
                                            <p>{news.short_text}</p>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Col>
                        <Col md="3">
                            <h5 className='text-center mt-5'> Categories </h5>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>HTML/CSS</ListGroup.Item>
                                    <ListGroup.Item>JavaScript</ListGroup.Item>
                                    <ListGroup.Item>Java</ListGroup.Item>
                                    <ListGroup.Item>C#</ListGroup.Item>
                                </ListGroup>
                            </Card>
                            <Card className='mt-3 bg-light'>
                                <Card.Body>
                                    <Card.Title>Side widget</Card.Title>
                                    <Card.Text>
                                        О какой текст крутой, типо как (притворитесь).
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default News;
