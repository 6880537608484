import React, { Component } from 'react';
import { Container, Tab, Nav, Row, Col } from 'react-bootstrap';

class About extends Component {
    render() {
        return (
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column mt-2">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Design</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Team</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Programming</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">Frameworks</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">Libraries</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={10} className='mt-2'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <img src="https://okta.ua/images/p/1426844601%D0%9E%20%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8.png"></img>
                                    <p>
                                        Не ну реально в лом писать много, притворитесь, что здесь текст крутой и вам понравилось.
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <img src="https://okta.ua/images/promo/l/1524059751m_Horotec.jpg"></img>
                                    <p>
                                        Не ну реально в лом писать много, притворитесь, что здесь текст крутой и вам понравилось.
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <img src="https://okta.ua/images/news/l/1692213513m_okta-rios-5.webp"></img>
                                    <p>
                                        Не ну реально в лом писать много, притворитесь, что здесь текст крутой и вам понравилось.
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <img src="https://okta.ua/images/news/l/1691135926m_1689667239m_1687986242m_1662446971m_1626785753m_%D0%9A%D0%B8%D1%97%D0%B2-%D1%84%D1%96%D0%BB%D1%96%D1%8F-%D0%BE%D0%BA%D1%82%D0%B0-5.webp"></img>
                                    <p>
                                        Не ну реально в лом писать много, притворитесь, что здесь текст крутой и вам понравилось.
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <img src="https://okta.ua/images/news/l/1673515657m_renata_innovation_vollautomatische-silberoxid_big_default.jpg"></img>
                                    <p>
                                        Не ну реально в лом писать много, притворитесь, что здесь текст крутой и вам понравилось.
                                    </p>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        );
    }
}

export default About;